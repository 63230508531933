/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */
.container {
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 56px;
  padding: 8px 8px 8px 7px;
  padding-left: 19px;
  background: rgba(255, 255, 255, 0.7);
  border: 1.2px solid #1c70ee;
  border-radius: 16px;
  box-shadow: 0 8px 10px -3px rgba(0, 102, 255, 0.1);

  :global {
    .ant-select-auto-complete {
      width: 100%;

      input {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #b8b8b8;
      }

      .ant-select-selector {
        padding: 0 !important;
        border: none !important;

        .ant-select-selection-search {
          left: 0;
        }

        .ant-select-dropdown {
          width: calc(100% + 45px);
          min-width: calc(100% + 45px);
          max-width: calc(100% + 45px);
        }

        .ant-select-selection-wrap {
          .ant-select-selection-search {
            input {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              color: #000;
            }
          }

          .ant-select-selection-placeholder {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: #000;
          }
        }
      }
    }

    .ant-select-auto-complete.ant-select-focused {
      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;

        .ant-select-selection-search {
          left: 0;
        }
      }
    }
  }

  .searchButton {
    display: flex;
    flex: 0 0 40px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 16px 8px;
    background: #1c70ee;
    border-radius: 12px;

    svg {
      flex: 0 0 22px;
    }
  }
}

.groupTitle {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #acaeb1;
}

.autoCompleteItem {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #111212;

  img {
    border-radius: 5px;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.customAutoComplete {
  :global {
    .ant-select-item {
      padding: 12px !important;
    }

    .ant-select-item-option-grouped {
      padding-inline-start: 12px !important;
    }
  }
}

.chatButton {

  /* stylelint-disable-next-line selector-class-pattern */
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    color: #fff;
    background: #1C70EE !important;
    border-radius: 10px !important;
    box-shadow: none;

    &:hover {
      background: #0D6EFF !important;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */

  /* stylelint-disable-next-line selector-class-pattern */
  .button[disabled] {
    text-shadow: none;
    background: #f5f5f5 !important;
    border: none;
    border-color: #d9d9d9 !important;
    box-shadow: none;
  }

}
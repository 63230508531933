.jobHeader {
  position: relative;

  // padding: 40px 40px 32px;

  .companyName {
    margin: 8px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #4b4c4d;
  }

  .horLine {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background: #f4f5f6;
  }

  .pending {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #ff9500;
    background: rgba(255, 234, 204, 0.5);
    border-radius: 0 16px 0 12px;
  }

  h3 {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #000;
  }

  .salaryInfo {
    display: flex;
    align-items: center;
    margin-right: 24px;

    // margin: 12px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #000;

    .type {
      margin: 0 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #000;
    }
  }

  .detailInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .infoContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 6px 0;

    span {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #515151;

      svg {
        margin-right: 4px;
      }
    }

    .urgent {
      display: flex;
      align-items: center;
      height: 24px;
      padding: 0 6px;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      background: #4bd9e3;
      border-radius: 6px;
    }
  }

  .exchangeBtn {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    height: 16px;
    padding: 4px 6px 4px 8px;
    margin-left: 7px;
    cursor: pointer;
    background: #f6f6f6;
    border-radius: 19px;

    &:hover {
      background-color: #e1eeff;
    }

    :global {
      .ant-dropdown-trigger:hover {
        background-color: transparent;
      }

      .ant-dropdown-trigger:hover svg:first-child path:nth-of-type(1) {
        stroke: #2378e5;
      }

      .ant-dropdown-trigger:hover svg:first-child path:nth-of-type(2) {
        stroke: #2378e5;
      }

      .ant-dropdown-trigger:hover svg:first-child path:nth-of-type(3) {
        stroke: #2378e5;
      }

      .ant-dropdown-trigger:hover svg:first-child path:nth-of-type(4) {
        fill: #2378e5;
      }

      .ant-dropdown-trigger:hover svg:first-child path:nth-of-type(5) {
        fill: #2378e5;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      .ant-dropdown-trigger:hover svg:last-child path {
        fill: #2378e5;
      }
    }
  }
}

.moneyDropdownWrapper {
  width: 360px;
  padding: 28px 0 !important;
  list-style-type: none !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 12px !important;
  outline: none !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  .header {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #000;
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-dropdown-menu {
      max-height: 460px;
      padding: 0 !important;
      padding: 0 20px !important;
      margin-right: 2px !important;
      overflow: hidden;
      overflow-y: auto;
      background-color: #fff !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #f4f5f6;
        border-radius: 6px;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-dropdown-menu-item {
      align-items: center;
      align-self: stretch;
      justify-content: space-between;
      padding: 0 !important;

      /* stylelint-disable-next-line selector-class-pattern */
      .ant-dropdown-menu-title-content {
        padding: 0 !important;
        margin: 0 !important;
      }

      span {
        font-size: 16px !important;
        font-weight: 500;
        line-height: 22px;
      }

      &:hover {
        background: transparent !important;
      }
    }
  }

  .exchangeMoneyListWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 4px !important;
    color: #121212;

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background: transparent;
      border: 1.6px solid #dbdfe4;
      border-radius: 24px;

      svg {
        margin: 0;
      }
    }

    .checked {
      background-color: #1c70ee;
      border-color: #1c70ee;
    }

    &:hover {
      background: #f8f9fa;
      border-radius: 8px;
    }

    &[data-checked="true"] {
      background: #f8f9fa !important;
      border-radius: 8px !important;
    }

    :global {
      .ant-dropdown-menu-item {
        font-size: 12px !important;
      }
    }
  }

  .listSalary {
    // color: #121212;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
  }
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-class-pattern */

.wrapperSelect {
  :global {
    .ant-select {
      height: 44px;
    }

    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      display: flex;
      gap: 8px;
      align-items: center;
      align-self: stretch;
      height: 44px;
      padding: 8px 16px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%),
        rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(0, 102, 255, 0.1);
      border-radius: 12px;
      box-shadow: 0 4px 6px 0 rgba(0, 102, 255, 0.05);

      .ant-select-selection-item {
        .selectLabel,
        .selectLabelPlaceholder {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          /* 150% */
          color: #000;
        }
      }

      // &:hover {
      //   background: #f5f7fb;

      //   .ant-select-selection-item {
      //     .selectLabel,
      //     .selectLabelPlaceholder {
      //       font-size: 16px;
      //       font-style: normal;
      //       font-weight: 500;
      //       line-height: 24px; /* 150% */
      //       color: #000;
      //     }
      //   }
      // }
    }

    .ant-select.ant-select-focused {
      .ant-select-selector {
        // background: #f5f7fb;
        border-color: #e8e8ea !important;
      }

      .selectLabel,
      .selectLabelPlaceholder {
        color: #000;
      }

      .activeLabel {
        color: #1c70ee;
      }
    }

    .ant-select.activeLabel {
      .ant-select-selector {
        border-color: #ebeef0 !important;
      }

      .selectLabel,
      .selectLabelPlaceholder {
        color: #1c70ee;
      }
    }

    .activeLabel {
      .selectLabel,
      .selectLabelPlaceholder {
        color: #1c70ee;
      }
    }

    .ant-select {
      cursor: pointer;

      .ant-select-selector {
        box-shadow: 0 4px 6px 0 rgba(0, 102, 255, 0.05);
      }

      &:hover {
        > .ant-select-selector {
          border: 1px solid rgba(28, 112, 238, 0.5) !important;
          border-color: rgba(28, 112, 238, 0.5) !important;
          box-shadow: 0 0 0 2px rgba(28, 112, 238, 0.2) !important;
        }
      }

      .ant-select-arrow {
        opacity: unset;
      }

      .ant-select-dropdown {
        padding: 0;
        background: #fff;
        border: 1px solid #f6f6f6;
        border-radius: 8px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      }

      .ant-select-selector {
        .ant-select-selection-wrap {
          .ant-select-selection-item {
            overflow: visible;
          }
        }
      }
    }

    .ant-select.activeLabel {
      &:hover {
        > .ant-select-selector {
          border-color: #ebeef0 !important;
        }
      }
    }
  }
}

.wrapperSelectActive {
  :global {
    .ant-select {
      .ant-select-arrow {
        transform: rotate(180deg);
      }
    }
  }
}

.overrideSelect {
  width: 100%;

  :global {
    .ant-select-dropdown {
      width: auto !important;
    }

    // .ant-select-selector {
    //   border-radius: 24px;
    // }

    .ant-cascader-panel-empty {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.inputLabel {
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;

  :global {
    .selectLabel,
    .selectLabelPlaceholder {
      flex: 1;
      overflow: hidden;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      /* 150% */
      color: #000;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .size {
    padding: 4px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #1c70ee;
    text-align: center;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 10px;
  }
}

.whereLabelWarp {
  position: relative;
  display: flex;
  gap: 17px;
  place-items: center center;

  > svg {
    flex: 0 0 18px;
  }

  .size {
    position: absolute;
    top: 50%;
    right: -60px;
    padding: 4px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: #1c70ee;
    text-align: center;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 10px;
    transform: translateY(-50%);
  }
}

.whereLabel {
  display: flex;
  flex-direction: column;
  place-items: center center;

  .whereMinLabel {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    /* 128.571% */
    color: #000;
  }

  :global {
    .selectLabelPlaceholder {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      /* 150% */
      color: #000;
    }

    .selectLabel {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: #1c70ee;
    }
  }
}

// in board
.wrapperSelect {
  .autoCompleteWrapper {
    padding: 12px 16px;
    border-bottom: 1px solid #f6f6f6;

    .overrideAutoComplete {
      width: 100%;
      height: 38px;

      .highlight {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #1c70ee;
      }

      :global {
        .ant-select-dropdown {
          width: 100% !important;

          .rc-virtual-list {
            .rc-virtual-list-holder {
              .rc-virtual-list-holder-inner {
                .ant-select-item {
                  border-bottom: 1px solid #f6f6f6;
                }
              }
            }
          }
        }

        .ant-select-selector {
          padding: 11px 12px;
          background: #fff;
          border: 1.5px solid #ebeef0;
          border-radius: 12px;

          .ant-select-prefix {
            display: flex;
          }

          .ant-select-selection-wrap {
            height: 100%;

            .ant-select-selection-search {
              height: 100%;

              .ant-select-selection-search-input {
                height: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #646566;
              }
            }
          }

          .ant-select-selection-wrap {
            .ant-select-selection-placeholder {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              color: #646566;
            }
          }
        }
      }
    }

    :global {
      .ant-select {
        > .ant-select-selector {
          border: 1.5px solid #ebeef0 !important;
          border-color: #ebeef0 !important;
          box-shadow: unset !important;
        }
      }
    }
  }
}

.overridePanel {
  border: unset !important;

  :global {
    .ant-cascader-menus {
      .ant-cascader-menu {
        width: 220px;
        max-height: 404px;
        padding: 0;

        /* 滚动条整体的宽度 */
        ::-webkit-scrollbar {
          width: 12px;

          /* 可以根据需要调整宽度 */
        }

        /* 滚动条轨道的颜色 */
        ::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 10px;

          /* 使轨道带圆角 */
        }

        /* 滚动条的颜色 */
        ::-webkit-scrollbar-thumb {
          background-color: #dbdfe4;

          /* 滚动条颜色 */
          border-radius: 10px;
        }

        /* 当滚动条被悬停时的颜色 */
        ::-webkit-scrollbar-thumb:hover {
          background-color: #dbdfe4;
        }

        /* 滚动条的颜色，前者为滚动条颜色，后者为轨道颜色 */
        scrollbar-color: #dbdfe4 #fff;

        /* 滚动条宽度 (可选值：auto, thin, none) */
        scrollbar-width: thin;

        /* 设置滚动条宽度 */

        .ant-cascader-menu-item {
          padding: 12px 8px 12px 16px;

          .ant-cascader-menu-item-content {
            width: 150px;
            overflow: hidden;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #515151;
            white-space: normal;
          }
        }

        .ant-cascader-menu-item {
          border-radius: unset;

          .ant-cascader-checkbox-checked {
            .ant-cascader-checkbox-inner {
              background-color: #1c70ee;
              border-color: #1c70ee;
            }
          }
        }
      }
    }

    .ant-cascader-menu-item {
      .ant-cascader-checkbox-indeterminate {
        .ant-cascader-checkbox-inner {
          background-color: #1c70ee !important;
          border-color: #1c70ee !important;

          &::after {
            position: absolute;
            inset-inline-start: 25%;
            top: 50%;
            box-sizing: border-box;
            display: table;
            width: 5.714px;
            height: 9.14px;
            content: "";
            border: 2px solid #fcfcfc;
            border-inline-start: 0;
            border-top: 0;
            border-bottom: 0;
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            transform: rotate(90deg) scale(1) translate(-110%, -10%);
          }
        }

        &:hover {
          .ant-cascader-checkbox-inner {
            background-color: #1c70ee !important;
          }
        }
      }
    }
  }
}

.footer_in_board {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 12px 16px;
  border-top: 1px solid #f6f6f6;

  .footer_in_board_left {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #1c70ee;
  }

  .footer_in_board_right {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    background: #1c70ee;
    border-radius: 8px;
    box-shadow: unset;
  }
}

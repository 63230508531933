/* stylelint-disable */

.listItem {
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  transition: border 0.4s ease;

  .wrapper {
    padding: 16px 24px 12px 24px;

    // border: 1px solid #fff;
    border-radius: 16px;
  }

  .container {
    height: 28px;
    overflow: hidden;

    .salary {
      display: flex;
      flex-direction: column;
      padding-top: 2px;
      overflow-y: hidden;
      transition: 0.3s;
    }

    .salaryText {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: #1c70ee;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .month {
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #000;
    }

    .chat {
      transition: 0.3s;
      transform: translateY(10px);
    }

    :global {
      .ant-btn-primary {
        background: #1c70ee;
        border-radius: 8px;

        > span {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          color: #fff;
        }

        &:hover {
          background: #1c70ee !important;
        }
      }
    }
  }

  // &:hover {
  //   margin-top: 6px;
  // }
}

.listItemActive {
  border: 1px solid rgba(28, 112, 238, 0.5);
}

.listItem:hover {
  border: 1.2px solid rgba(28, 112, 238, 0.5);
  box-shadow: 0px 0px 0px 2px rgba(28, 112, 238, 0.1);
}

.listTag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 4px;
}

.jobCardLocationItem {
  display: inline-block;
  height: 24px;
  padding: 0 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #3b3c3c;
  background: #f8f9fa;
  border-radius: 8px;
}

.jobHireTop {
  &:hover {
    .jobHireTopTitle {
      color: #1c70ee;
    }
  }
}

.jobHireTopTitle {
  overflow: hidden;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #000;
}

.jobHireTitleUrgent {
  display: inline-block;
  height: 22px;
  padding: 0 6px;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #000;
  vertical-align: text-bottom;
  background: #3ceaf6;
  border-radius: 6px;

  svg {
    vertical-align: middle;
  }
}

.jobTabLoading {
  position: relative;
  height: 700px;
}

.listCompany {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  border-top: 1px solid #ebeef0;

  .listCompanyLogo {
    position: relative;
    display: flex;

    img {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      margin-right: 9px;
      border-radius: 50px;
      border: 1.333px solid #fff;
      box-shadow: 0px 5.333px 8px 0px rgba(162, 183, 203, 0.1);
      outline: none;
    }
  }

  .listCompanyLogoActive {
    &::before {
      content: "";
      position: absolute;
      right: 10px;
      bottom: 0px;
      display: block;
      width: 9px;
      height: 9px;
      background: #34c759;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
}

.jobHireCompanyName {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  display: flex;
  align-items: center;
  width: calc(100% - 41px);
  overflow: hidden;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 65%;
  }

  span {
    color: #646566;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  b {
    color: #acaeb1;
    flex: 0 0 20px;
    display: flex;
    justify-content: center;
  }
}

.listItemChat {
  &:hover {
    border: 1px solid rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1), 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    .salary {
      transition: 0.3s;
      transform: translateY(-30px);
    }

    .chat {
      transition: 0.3s;
      transform: translateY(-30px);
    }

    .title {
      color: #1c70ee;
    }
  }

  .container {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 32px;
  }
}

.company_name {
  color: #3b3c3c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable CssSyntaxError */

@import "styles/variable";
@import "styles/mixins";

.container_mobile {
  background-color: #fff;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px 20px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

  .theBestTitleInPage {
    margin: 0 auto;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #000;
      letter-spacing: 0.15px;
    }
  }
}

.search {
  width: 100%;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .defaultFiled {
    flex: 0 0 calc(50% - 6px);
    width: calc(50% - 6px);
  }

  .openFilter,
  .switch {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    transform: translateX(-10px);

    :global {
      .ant-switch.ant-switch-checked {
        background: #1890ff;
      }

      .ant-switch {
        transform: scale(0.8) translateX(10px);
      }
    }
  }

  .switch {
    .describe {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #7d7d7d;
      text-align: right;
    }
  }

  .openFilter {
    gap: 10px;

    .describe {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #515151;
      text-align: center;
    }

    .openFilterActive {
      display: flex;
      gap: 4px;
      place-items: center center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #2378e5;

      span {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        height: 16px;
        padding: 0 4px;
        background: #e1eeff;
        border-radius: 10px;
      }
    }
  }
}

.drawer {
  background-color: rebeccapurple;

  :global {
    .ant-drawer-body {
      width: 100%;
      padding: 0 !important;
      overflow: hidden;
    }
  }

  .drawerHeader {
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 12px 20px;
    background-color: #fff;

    .drawerHeaderTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      color: #111212;
      text-align: center;
    }

    .drawerClose {
      position: absolute;
      top: 13px;
      right: 20px;
    }
  }
}

.drawerMain {
  height: calc(100% - (47px + 65px));
  padding: 0 20px;
  overflow: hidden;
  overflow-y: scroll;

  .drawerGroup {
    padding: 16px 0;
    border-bottom: 1px solid #f6f6f6;

    .drawerSelectWrapper {
      padding: 8px 0 12px;
    }

    .drawerTitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #121212;
    }

    :global {
      .ant-space {
        width: 100%;
      }

      .ant-radio-group {
        .ant-space-item {
          .ant-radio-wrapper {
            .ant-radio + span {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: #515151;
            }
          }
        }
      }

      .ant-checkbox-group {
        gap: 16px 0;

        .ant-checkbox-wrapper.ant-checkbox-group-item {
          flex: 0 0 50%;

          .ant-checkbox + span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #515151;
          }
        }
      }
    }
  }

  .drawerMainBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;

      /* 143.75% */
      color: #111212;
      text-align: center;
    }
  }
}

.drawerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .drawerButtons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    background: #fff;
    border-top: 1px solid #f6f6f6;
    box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.03);

    :global {
      .ant-btn-default {
        display: flex;
        flex: 1 0;
        gap: 8px;
        align-items: center;
        justify-content: center;
        min-width: 163px;
        height: 40px;

        // padding: 12px 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: #1c70ee;
        white-space: normal;
        background: #fff;
        border: 1px solid #1c70ee;
        border-radius: 10px;
      }

      .ant-btn-primary {
        display: flex;
        flex: 1 0;
        gap: 4px;
        align-items: center;
        justify-content: center;
        min-width: 163px;
        height: 40px;

        // padding: 12px 32px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #fff;
        white-space: normal;
        background: #1c70ee;
        border-radius: 10px;
      }
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 70vh;
  padding: 8px 12px;

  .viewMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    .viewMoreBtn {
      height: 36px;
      padding: 12px 32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      color: #000;
      background: #fff;
      border-radius: 8px;
    }
  }
}

.moreButton {
  display: block;
  width: 146px;
  height: 44px;
  margin: 26px auto 0;
  font-size: 14px;
  line-height: 44px;
  color: #1d2129;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c9cdd4;
  border-radius: 4px;
}

.jobCardMoblie {
  position: relative;
  border-bottom: 1px solid #f6f6f6;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px 0;
    overflow: hidden;
    cursor: pointer;
    background: #fff;

    .titleContainer {
      width: 100%;

      .urgent {
        display: inline-block;
        align-items: center;
        justify-content: center;
        padding: 4px 4px 4px 3px;
        margin-right: 4px;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        color: #fffefe;
        letter-spacing: 0.075px;
        vertical-align: top;
        background: linear-gradient(128deg, #f64a88 0%, #f56a6a 100%);
        border-radius: 4px;
        box-shadow: 0 4px 13px 0 rgba(255, 60, 60, 0.1);
      }

      .remote {
        background: linear-gradient(101deg, orange 6.03%, #ffcd7e 92.76%);
      }

      .title {
        display: -webkit-box;
        flex-shrink: 1;
        width: 70%;
        max-height: 48px;
        overflow: hidden;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        letter-spacing: 0.128px;

        /*! autoprefixer: off */
        -webkit-box-orient: vertical;

        /* autoprefixer: on */
      }

      .salary {
        padding-top: 4px;
        margin-bottom: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        color: #2378e5;
        letter-spacing: 0.113px;

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          color: #2378e5;
        }
      }
    }

    .titleContainerUrgent {
      justify-content: start;
      padding-bottom: 8px;
    }

    .companyInfo {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #4e5969;
      letter-spacing: 0.28px;
    }

    .companyName {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #121212;
      letter-spacing: 1px;
    }

    .companySize {
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .companyInfoSpread {
      display: inline-block;
      width: 0;
      height: 12px;
      margin: 0 10px;
      vertical-align: bottom;
      border-left: 1px solid #e5e6eb;
    }

    .recruiterLine {
      display: block;
      width: 100%;
      height: 1px;
      margin: 16px 0;
      background: #f2f3f5;
    }

    .recruiterContainer {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      width: 100%;
      margin-top: 8px;

      .hrTitle {
        display: block;
        overflow: hidden;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #515151;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: #7d7d7d;
        }
      }

      .button {
        display: none;
        width: fit-content;
        height: 24px;
        padding: 4px 9px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #fff;
        letter-spacing: 0.0075em;
        background: #136fd3;
        border-radius: 12px;

        &.isHover {
          display: flex;
          height: 24px;
          cursor: pointer;
        }
      }

      // height: 21px;

      .online {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        padding: 5px 8px;
        margin-left: 8px;
        font-size: 12px;
        color: #4caf50;
        background: #f2f9f3;
        border-radius: 15px;

        &::before {
          width: 8px;
          height: 8px;
          margin-right: 3px;
          content: " ";
          background: #4caf50;
          border: 1px solid #fff;
          border-radius: 4px;
        }

        :first-child {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          color: #fff;
          letter-spacing: 0.0075em;
        }
      }
    }
  }

  .jobLocationAndType {
    display: flex;
    align-items: center;

    .tag {
      flex-shrink: 0;
      max-width: 190px;
      padding: 0 8px;
      margin-right: 8px;
      overflow: hidden;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #3a90ff;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: #eef5ff;
      border-radius: 4px;
    }

    .labelContainer {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        margin: 0 0 3px;
      }

      .label {
        margin: 0 7px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #7d7d7d;
      }
    }
  }
}

.imgContainer {
  position: relative;
  overflow: visible;

  img {
    position: relative;
    overflow: hidden;
    vertical-align: bottom;
    border: 1px solid #f6f6f6;
    border-radius: 12px;
    border-bottom-left-radius: 0;
  }

  .defaultAvatar {
    border: 1px solid #34c759;
  }

  .isOnline {
    position: absolute;
    right: -2px;
    bottom: -3px;
    width: 9px;
    height: 9px;
    background: #0ebd5c;
    border: #fff solid 1px;
    border-radius: 4.5px;
  }
}

.companyNameShow {
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #121212;
}

.profiled {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
  padding-bottom: 0;

  :global {
    .complete-profile-card {
      margin-bottom: 0;
      border-radius: 16px;
    }
  }
}

.emptyContainer {
  :global {
    .yolo-empty-container {
      min-height: 70vh;
    }
  }
}

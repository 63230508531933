.content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 20px;
}
.container {
    width: 100%;
    height: 72px;
    position: relative;

    .left {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        right: 0px;
        background: transparent;
        @extend .content;
    }

    .right {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        right: 0px;
        background: transparent;
        // transform: translateX();
        @extend .content;
    }

    .indicatorContainer {
        width: 64px;
        height: 4px;
        position: absolute;
        bottom: 0px;
        left: calc(50% - 32px);
        background: #f0f0f0;
        border-radius: 2px;
        overflow: hidden;
        .indicator {
            width: 32px;
            height: 4px;
            position: absolute;
            bottom: 0px;
            left: calc(50% - 32px);
            background: #136FD3;
            border-radius: 2px;
            overflow: hidden;
        }
    }
}
.item {
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &Content {
        height: 18px;
        left: 140px;
        top: 5px;
        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        letter-spacing: 0.0075em;
        color: #353535;
    }
    &Title {
        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        align-items: center;
        letter-spacing: 0.0075em;
        color: #707070;
    }
}
.loadingSke {
  width: 100%;
  height: 145px;
  padding: 14px 24px 0;
  margin-bottom: 16px;
  margin-left: 0;
  background: #fff;
  border-radius: 10px;

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
